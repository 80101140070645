import axios from 'axios';
import qs from 'qs'

axios.defaults.timeout = 5000;      // 超时时间

//axios.defaults.baseURL = 'https://www.madeinjq.com';  //填写域名
axios.defaults.baseURL = 'https://gxzz.lnkj6.com';  //填写域名



//http request 拦截器
axios.interceptors.request.use(
    config => {
        console.log(localStorage['yhtoken'])
        let data = config.data || {};
        if(data.token == true) {
            data.token = localStorage['yhtoken'];
        }
        config.data = qs.stringify(config.data);
        config.headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
    return response
}, err => {
    if (err && err.response) {
        switch (err.response.code) {
            case 400:
                console.log('错误请求')
                break;
            case 401:
                console.log('未授权，请重新登录');
                this.$router.push({
                    name: 'login'
                })
                break;
            case 403:
                console.log('拒绝访问')
                break;
            case 404:
                console.log('请求错误,未找到该资源')
                break;
            case 405:
                console.log('请求方法未允许')
                break;
            case 408:
                console.log('请求超时')
                break;
            case 500:
                console.log('服务器端出错')
                break;
            case 501:
                console.log('网络未实现')
                break;
            case 502:
                console.log('网络错误')
                break;
            case 503:
                console.log('服务不可用')
                break;
            case 504:
                console.log('网络超时')
                break;
            case 505:
                console.log('http版本不支持该请求')
                break;
            default:
                console.log(`连接错误${err.response.status}`)
        }
    } else {
        console.log('连接到服务器失败')
    }
    return Promise.resolve(err.response)
})


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
                console.log(response.data);

            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */

export function post(url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config)
            .then(response => {
                if(response.data.code == 403 || response.data.code == 402){
                    console.log(1322)
                    this.$message('请先登录');
                    this.$router.push({name:'Login'})
                }else{
                    resolve(response.data);
                }

                console.log(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


const uploadObject = axios.create(
    {
        headers: {
            'Content-Type': 'multipart/form-data; charset=utf-8'
        },
        method: 'post'
    }
);

export function upload(obj) {
    return uploadObject(obj);
}

// function request(url,data,succes,fail){
//     let xml;
//     if (window.XMLHttpRequest) {
//         xml = new XMLHttpRequest();
//     } else if (window.ActiveX) {
//         xml = new window.ActiveX('fasfasfa');
//     }
//
//     xml.setRequestHeader('Content-ty...');
//
//
//     xml.open('post','https://www.baidu.com');
//
//     xml.send();
//
//
//     xml.onreadystatechange = function (readState) {
//
//         if (xml.status === 200  && xml.readyState === 4) {
//             xml.responseText;
//         }
//
//     };
// }





