export default{
    data(){
        return{

        }
    },
    methods:{
        openMq:function(){
            (function(a, b, c, d, e, j, s) {
                a[d] = a[d] || function() {
                    (a[d].a = a[d].a || []).push(arguments)
                };
                j = b.createElement(c),
                    s = b.getElementsByTagName(c)[0];
                j.async = true;
                j.charset = 'UTF-8';
                j.src = 'https://static.meiqia.com/widget/loader.js';
                s.parentNode.insertBefore(j, s);
            })(window, document, 'script', '_MEIQIA');
            _MEIQIA('entId', '82dfd6e8e63cce3a34900edcb8df3258');
            _MEIQIA('showPanel');
        }
    }
}