import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import promise from 'es6-promise'
import '@babel/polyfill';
import 'element-ui/lib/theme-chalk/index.css';
import './net/index'
Vue.config.productionTip = false;
Vue.use(ElementUI);
promise.polyfill();
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
