export default {

    canDo: {
        title: '我们能做什么',
        content: '长期致力于PP订制开发、微信开发、网站建设，了解客户所需所想，直击需求痛点！我们一直与时俱进，用心做好每一个产品，并努力实现客户价值最大化，让客户更满意！'
    },
    development: {
        title: '企业治理与发展',
        content: '长期致力于PP订制开发、微信开发、网站建设，了解客户所需所想，直击需求痛点！我们一直与时俱进，用心做好每一个产品，并努力实现客户价值最大化，让客户更满意！'
    }, port: {

    },
    otherPort: {
        title: '更多端口',
        content: [
            {title:'APP--客户端',url:''} 
        ]
    },
    clause: {
        title: '服务条款',
        content: [
           {id:0,title:''}
        ]
    },

    friendLink: {
        title: '友情链接',
        list: [
            {
                id: 1,
                title: '服务条款',
                url: 'www.baidu.com'
            },
            {
                id: 2,
                title: '服务条款',
                url: 'www.baidu.com'
            }, {
                id: 3,
                title: '服务条款',
                url: 'www.baidu.com'
            },
        ]
    },
    operationList: [
       {
            id: 3,
            icon: require('../../../assets/images/fc_iocn_wyct.png'),
            text: '我要传图',
            link: '/tz-upload',
            type: 1
        }, {
            id: 4,
            icon: require('../../../assets/images/fc_iocn_ddcx.png'),
            text: '订单查询',
            link: '/user-center/order',
            type: 1
        }, {
            id: 5,
            icon: require('../../../assets/images/fc_iocn_fwzx.png'),
            text: '服务查询',
            link: '/service',
            type: 1
        },
        //  {
        //     id: 6,
        //     icon: require('../../../assets/images/fc_iocn_lxdh.png'),
        //     text: '联系电话',
        //     link: '/about',
        //     type: 1
        // }
    ]
}