import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin:localStorage['isLogin'] || 0,
    userInfo:localStorage['isLogin'] ? JSON.parse(localStorage['user']) : {}
  },
  getters:{
    getCount(state){
      return state.isLogin
    }
  },
  mutations: {
    changeLogin(state,num){
      console.log(num)
      state.isLogin = num;
      if(num == 1){
        state.userInfo = JSON.parse(localStorage['user']) || {}
      }else{
        state.userInfo =  {}
      }
     
  
      console.log(12312)
    }
  },
  actions: {},
  modules: {},
});
