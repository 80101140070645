<template>
  <div class="base">
    <div class="c-width">
      <div class="header cf">
        <router-link class="fl" to="/">
          <div class="logo">
            <img :src="logourl" alt="" />
          </div>
        </router-link>
        <div class="fl nav">
          <router-link to="/" exact>首页</router-link>
          <router-link to="/service">我们的服务</router-link>
          <router-link to="/user-center">我的专区</router-link>
          <!-- <router-link to="/case">案例展示</router-link> -->
          <router-link to="/news">新闻资讯</router-link>
          <router-link to="/about">关于我们</router-link>
        </div>
        <div
          class="fr loginout"
          v-if="this.$store.state.isLogin == 1"
          @click="loginout"
        >
          退出
        </div>
        <div
          v-if="this.$store.state.isLogin == 1"
          class="cf user-box fr"
          @click="toUser"
        >
          <img
            class="user-img fl"
            :src="
              this.$store.state.userInfo.avatar
                ? this.$store.state.userInfo.avatar
                : require('../../../assets/images/grzx_iocn_tx.png')
            "
            alt=""
          />
          <div class="fl user-name">
            {{
              this.$store.state.userInfo.user_type == 1
                ? "个人"
                : this.$store.state.userInfo.user_type == 2
                ? "企业"
                : "供应商"
            }}:{{
              this.$store.state.userInfo.name ||
              this.$store.state.userInfo.enterprise_name
            }}
          </div>
        </div>
        <router-link v-else class="login-box fr" to="/login">
          <img src="../../../assets/images/common_nav_iocn_geren.png" alt="" />
          <span>你好，请</span>
          <span class="blue">登录{{ this.$store.state.count }}</span>
        </router-link>
      </div>
    </div>
    <router-view />
    <div class="fotter">
      <div class="c-width">
        <div class="do-box cf">
          <div class="do-item fl">
            <h6>{{ canDo.title }}</h6>
            <div class="text">
              <p
                style="margin-bottom: 8px"
                v-for="item in canDo.content"
                :key="item.id"
              >
                {{ item.title }}
              </p>
            </div>
          </div>
          <div class="do-item fl">
            <h6>{{ development.title }}</h6>
            <div class="text">
              <p
                style="margin-bottom: 8px"
                v-for="item in development.content"
                :key="item.id"
                @click="open(item.content, 1)"
              >
                {{ item.title }}
              </p>
            </div>
          </div>
          <div class="do-item fl">
            <h6>{{ otherPort.title }}</h6>
            <div class="link zz-code">
              矜群制造小程序
              <div class="code">
                <h3>矜群制造</h3>
                <img class="code-img" :src="user_wx.user_wx_code" alt="" />
              </div>
            </div>
            <div class="link gc-code">
              矜群工厂会员小程序

              <div class="code">
                <h3> 矜群工厂会员</h3>
                <img class="code-img" :src="gc_wx.gc_wx_code" alt="" />
              </div>
            </div>
            <div class="link" @click="togc">工厂pc端</div>
          </div>
          <div class="do-item fl">
            <h6>{{ clause.title }}</h6>
            <div
              class="link"
              v-for="(item, index) in clause.content"
              :key="index"
              @click="open(item.content, 2)"
            >
              <a>{{ item.title }}</a>
            </div>
          </div>
        </div>
        <div class="friend-link">
          <h6>友情链接</h6>
          <div class="list">
            <a
              v-for="item in friendLink.list"
              :key="item.id"
              :href="item.url"
              >{{ item.title }}</a
            >
          </div>
        </div>
        <div class="copyright">
          <p><a href="https://beian.miit.gov.cn/" style="color: #FFFFFF;" >{{shop_beian}}</a> Copyright 2021 {{shop_name}}</p>
        </div>
      </div>
    </div>
    <div class="alert-box" v-if="show">
      <div class="cf">
        <div class="fr close"  v-if="alertype == 1" @click="close">X</div>
      </div>
      <div class="context" v-html="content"></div>
      <div class="close-btn" v-if="alertype == 2" @click="close">
        阅读并同意该协议
      </div>
    </div>
    <div class="r-column">
      <div class="r-c-box">
        <div class="item" @click="openMq">
          <img src="../../../assets/images/fc_iocn_zxkf.png" alt="" />
          <span>在线客服</span>
        </div>
        <div class="item wxxd">
          <img src="../../../assets/images/fc_iocn_wxxd.png" alt="" />
          <span>微信登录</span>
          <div class="code">
            <h3>矜群制造</h3>
            <img class="code-img" :src="user_wx.user_wx_code" alt="" />
          </div>
        </div>
        <router-link
          class="item"
          v-for="item in operationList"
          :key="item.id"
          :to="item.link"
        >
          <img :src="item.icon" alt="" />
          <span>{{ item.text }}</span>
        </router-link>
        <div class="item tx-out">
          <img src="../../../assets/images/fc_iocn_lxdh.png" alt="" />
          <span>联系电话</span>
          <div class="tx-box">
            <h3>客服热线</h3>
            <p>4000-171-668</p>
            <h3 style="margin-top: 20px">服务时间</h3>
            <p>周一至周六</p>
            <p>09:00～21:00</p>
            <p>周日</p>
            <p>10:00～18:00</p>
          </div>
        </div>
      </div>
      <div class="backtop" @click="backTop">
        <img src="../../../assets/images/fc_iocn_fhdb.png" alt="" />
        <span>返回顶部</span>
      </div>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
};
</script>
<style scoped>
@import "../base.css";
</style>