import footData from "../data/footData";
export default {
    data: function () {
        return {
            canDo: footData.canDo,
            development: footData.development,
            otherPort: footData.otherPort,
            clause: footData.clause,
            friendLink: footData.friendLink,
            operationList: footData.operationList,
            logourl:'',
            gc_app: {},
            gc_wx: {},
            user_app: {},
            user_wx: {},
            show:false,
            index:0,
            content:'',
            alertype:1,
            shop_address: "北京市朝阳区上线6号",
            shop_beian: "京ICP备2021018702号",
            shop_email: "JQ@madeinjq.com",
            shop_name: "北京矜群科技有限公司",
            
        }
    },
    created(){
        this.getFriendUrl();
        this.getServiceTerms();
        this.getLogo();
        this.getQrcode();
        this.getAbout();
    },
    methods: {
        getQrcode: function () {
            this.$post('/api/index/getQrcode.html', {}).then((res) => {
                if (res.code == 200) {
                    this.gc_app = res.data.gc_app;
                    this.gc_wx = res.data.gc_wx;
                    this.user_app = res.data.user_app;
                    this.user_wx = res.data.user_wx
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        togc:function(){
            console.log(this.otherPort)
            window.location.href=this.otherPort.content[2].url;    
        },
        oepnXz:function(){
            
        },
        backTop() {
            scrollTo(0, 0);
        },
        getFriendUrl:function(){
            this.$post('/api/index/friendUrl.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                    this.friendLink.list = res.data || [];
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getServiceTerms:function(){
            this.$post('/api/index/serviceTerms.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                    this.canDo = res.data.can_do || {};
                    this.development = res.data.development || {}
                    this.otherPort = res.data.port_list || {}
                    this.clause = res.data.servic_list || {}
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        getLogo:function(){
            this.$post('/api/base/getLogo.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                   this.logourl = res.url || ''
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        toTzUp:function(){
            this.$router.push({name:'TzUpload'})
        },
        loginout:function(){
            this.$confirm('确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                  console.log(1312)
                localStorage.clear();
                this.$store.commit('changeLogin',0)
                console.log(3123)
                this.$router.replace({path:'/login'})
              }).catch(() => {
                        
              });
    
        },
        toUser:function(){
            this.$router.push({name:'User-center'})
        },
        open:function(content,alertype){
            this.content = content
            this.show = true;
            this.alertype = alertype;
        },
        close:function(){
            this.show = false;
        },
        getAbout: function () {
            this.$post('/api/base/abountUs.html', {}).then((res) => {
                if (res.code == 200) {
                    this.shop_address = res.data.shop_address || '';
                    this.shop_beian = res.data.shop_beian || '';
                    this.shop_email = res.data.shop_email || '';
                    this.shop_mobile = res.data.shop_mobile || '';
                    this.shop_name = res.data.shop_name || '';
                    this.shop_statemen = res.data.shop_statemen || '';
                    this.shop_xy = res.data.shop_xy || ''
                } else {
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
    }

}