import Vue from "vue";
import VueRouter from "vue-router";
import Base from '../views/base/src/base.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Base",
    component: Base,
    // redirect:'/index',
    children: [
      {
        path: "/",
        name: "Index",
        component: () =>
          import("../views/index/src/index.vue"),
      },
      {
        path: "/service",
        name: "Service",
        component: () =>
          import("../views/service/src/service.vue"),
      },
      {
        path: "/tz-upload",
        name: "TzUpload",
        component: () =>
          import("../views/tz-upload/src/index.vue"),
      },

      {
        path: "/user-center",
        name: "User-center",
        redirect: '/user-center/order',
        component: () =>
          import("../views/user-center/src/user-center.vue"),
        children: [
          {
            path: "/user-center/user-data",
            name: "User-data",
            component: () =>
              import("../views/user-center/child/user-data/src/index.vue"),
          },
          {
            path: "/user-center/order",
            name: "Order",
            component: () =>
              import("../views/user-center/child/order/src/index.vue"),
          }, {
            path: "/user-center/order-online",
            name: "Order-online",
            component: () =>
              import("../views/user-center/child/order-online/src/index.vue"),
          }, {
            path: "/user-center/child-account",
            name: "Child-account",
            component: () =>
              import("../views/user-center/child/child-account/src/index.vue"),
          },{
            path: "/user-center/over-data",
            name: "Over-data",
            component: () =>
              import("../views/user-center/child/over-data/src/index.vue"),
          },
        ],
      },
      {
        path: "/case",
        name: "Case",
        component: () =>
          import("../views/case/src/case.vue"),
      },
      {
        path: "/news",
        name: "News",
        component: () =>
          import("../views/news/src/news.vue"),
      },
      {
        path: "/about",
        name: "About",
        component: () =>
          import("../views/about/src/about.vue"),
      },
      {
        path: "/server-detail",
        name: "ServerDerail",
        component: () =>
          import("../views/server-detail/src/server-detail.vue"),
      },
      {
        path: "/news-detail",
        name: "NewsDerail",
        component: () =>
          import("../views/news-detail/src/news-detail.vue"),
      },
      {
        path: "/order-detail",
        name: "OrderDetail",
        component: () =>
          import("../views/order-detail/src/index.vue"),
      },
      {
        path: "/sale-detail",
        name: "SaleDetail",
        component: () =>
          import("../views/sale-detail/src/index.vue"),
      },
      {
        path: "/mq",
        name: "mq",
        component: () =>
          import("../views/mq.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/login/src/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import("../views/register/src/register.vue"),
  }, {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () =>
      import("../views/forget-password/src/forget-password.vue"),
  }

];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
});

export default router;
